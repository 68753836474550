import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { client } from "@utils/axios-utils";
import { AxiosResponse } from "axios";

export interface UserExistData {
  data: {
    user_in_system: boolean;
  };
}

export interface UserExistParams {
  type: "phone_number" | "email";
  value: string;
  "g-recaptcha-response": string;
  items: string;
}

export const checkUserExist = (data: UserExistParams) =>
  client.post<UserExistData>("auth/check", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const checkUserExistMutationKey = ["check", "user", "exist"];

export const useCheckUserExistMutation = (
  options?: Partial<
    Omit<
      UseMutationOptions<
        AxiosResponse<UserExistData, unknown>,
        unknown,
        UserExistParams
      >,
      "mutationKey" | "mutationFn"
    >
  >,
) =>
  useMutation({
    mutationKey: checkUserExistMutationKey,
    mutationFn: checkUserExist,
    ...options,
  });
